<template>
<div>
    <div class="sub-nav">
        <div class="container">
            <div class="sub-nav-list">
                <span class="nav-title">Pricing</span>
                <hr class="vline">
                <div class="sub-nav-group">
                    <router-link to="/pricing/calculator" class="sub-nav-item">Price Calculator</router-link>
                    <router-link to="/pricing/package" class="sub-nav-item">Package</router-link>
                </div>
            </div>
        </div>
    </div>

    <router-view></router-view>
</div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>


</style>